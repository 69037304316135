var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": _vm.value },
        on: { "modal-closed": _vm.closeModal },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end p-3" },
                  [
                    _c("CancelButton", { on: { click: _vm.closeModal } }),
                    _c("SaveButton", {
                      staticClass: "mr-2",
                      attrs: {
                        loading: _vm.payoutStatus.LOADING,
                        disabled: !_vm.isFormValid,
                      },
                      on: { click: _vm.savePayout },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c(
            "ui-validate",
            {
              staticClass: "d-flex flex-wrap mx-3",
              on: { status: _vm.isFormAllValid },
            },
            [
              _c("ui-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.select",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                    modifiers: { select: true },
                  },
                ],
                staticClass: "w-100 mb-2",
                attrs: {
                  label: `${_vm.$t("CRM.Payouts.create.typeLabel")}*`,
                  placeholder: _vm.$t("CRM.Payouts.create.typePlaceholder"),
                  required: "",
                  name: "type",
                },
                domProps: {
                  value: _vm.inputs.type,
                  options: _vm.payoutTypes.map((payout, index) => ({
                    value: index,
                    label: payout,
                  })),
                },
                on: { selectoption: _vm.onSelectPayoutType },
              }),
              _c("ui-text-area", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.input",
                    value: {
                      isRequired: true,
                      isMinLength: {
                        message: "This field cannot be blank",
                        length: 1,
                      },
                      isMaxLength: {
                        message: `The number of characters allowed is ${_vm.maxTextAreaLength}`,
                        length: _vm.maxTextAreaLength,
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isMinLength: {\n            message: 'This field cannot be blank',\n            length: 1,\n          },\n          isMaxLength: {\n            message: `The number of characters allowed is ${maxTextAreaLength}`,\n            length: maxTextAreaLength,\n          },\n        }",
                    modifiers: { input: true },
                  },
                ],
                staticClass: "w-100 my-2",
                attrs: {
                  value: _vm.description,
                  label: `${_vm.$t("CRM.Payouts.create.reasonLabel")}* (${
                    _vm.textAreaCharsCount
                  } characters left)`,
                  placeholder: _vm.$t("CRM.Payouts.create.reasonPlaceholder"),
                  name: "description",
                  maxlength: _vm.maxTextAreaLength,
                },
                on: { changevalue: _vm.textAreaManager },
              }),
              _c(
                "div",
                { staticClass: "w-100 my-2" },
                [
                  _c("LabelWithTooltip", {
                    attrs: {
                      label: `${_vm.$t(
                        "CRM.Payouts.create.relatedBookingsLabel"
                      )}*`,
                      tooltip: _vm.$t(
                        "CRM.Payouts.create.relatedBookingsTooltip"
                      ),
                    },
                  }),
                  _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.select",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                        modifiers: { select: true },
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      filters: `user_uuid:${_vm.userUuid}`,
                      title: ({ id, vehicle_brand, vehicle_model }) =>
                        `${id} - ${vehicle_brand} ${vehicle_model}`,
                      placeholder: _vm.$t(
                        "CRM.Payouts.create.relatedBookingsPlaceholder"
                      ),
                      index: _vm.ALGOLIA_INDEXES.csBookings,
                      name: "booking",
                      "path-value": "uuid",
                    },
                    model: {
                      value: _vm.inputs.bookingUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "bookingUuid", $$v)
                      },
                      expression: "inputs.bookingUuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-100 my-2" },
                [
                  _c("LabelWithTooltip", {
                    attrs: {
                      label: `${_vm.$t("CRM.Payouts.create.attachmentLabel")}*`,
                      tooltip: _vm.$t("CRM.Payouts.create.attachmentTooltip"),
                    },
                  }),
                  _c("DragFileComponent", {
                    attrs: {
                      "accepted-formats": _vm.allowedUploadFiletypes,
                      "draggable-height": 100,
                      multiple: false,
                      "data-test-id": "file-input",
                    },
                    on: { change: _vm.onChangeFile },
                    model: {
                      value: _vm.inputs.attachment,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "attachment", $$v)
                      },
                      expression: "inputs.attachment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-100 my-2" },
                [
                  _c("LabelWithTooltip", {
                    attrs: {
                      label: _vm.$t("CRM.Payouts.create.relatedCaseLabel"),
                      tooltip: _vm.$t("CRM.Payouts.create.relatedCaseTooltip"),
                    },
                  }),
                  _c("MuiAlgoliaSelect", {
                    staticClass: "w-100",
                    attrs: {
                      filters: _vm.getOperatorFilter({
                        attribute: "cs_operator_id",
                      }),
                      title: (r) =>
                        `${r.id} - ${r.case_type_name_translations.en_GB}`,
                      placeholder: _vm.$t(
                        "CRM.Payouts.create.relatedCasePlaceholder"
                      ),
                      index: _vm.ALGOLIA_INDEXES.caseEvents,
                      name: "case_event_id",
                      "path-value": "id",
                    },
                    model: {
                      value: _vm.inputs.caseEventId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "caseEventId", $$v)
                      },
                      expression: "inputs.caseEventId",
                    },
                  }),
                ],
                1
              ),
              _c("ui-text-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.input",
                    value: {
                      incorrectFormatValidator: _vm.incorrectFormatValidator,
                      isRequired: true,
                    },
                    expression:
                      "{\n          incorrectFormatValidator,\n          isRequired: true,\n        }",
                    modifiers: { input: true },
                  },
                ],
                staticClass: "w-100 mt-2",
                attrs: {
                  value: _vm.inputs.amount,
                  type: _vm.INPUT_TYPES.number,
                  label: `${_vm.$t("CRM.Payouts.create.amountLabel")}*`,
                  placeholder: _vm.$t("CRM.Payouts.create.amountLabel"),
                  name: "amount",
                },
                on: {
                  changevalue: ({ detail }) => (_vm.inputs.amount = detail),
                },
              }),
              _c("span", { staticClass: "font-s" }, [
                _vm._v(_vm._s(_vm.$t("CRM.Payouts.create.amountTooltip"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }