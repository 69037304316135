<script>
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiModal,
} from '@emobg/motion-ui/v1';
import { cookie as cookieManager } from '@emobg/web-utils';

import { BUTTON_TYPES, Validate } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { friendlyFormatIBAN, isValidIBAN } from 'ibantools';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton, SaveButton } from '@/components';

export default {
  name: 'PayoutAccountFormComponent',
  components: {
    CancelButton,
    MuiAlgoliaSelect,
    MuiModal,
    SaveButton,
  },

  directives: {
    Validate,
  },

  props: {
    userUuid: {
      type: String,
      default: undefined,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    bankAccount: {
      type: Object,
      default: undefined,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      payoutPaymentMethod: {
        holder: '',
        country: null,
        number: '',
      },
      isIbanCorrect: undefined,
      isFormValid: false,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.crm.payouts, {
      payoutStatus: state => state.paymentMethod.STATUS,
      payoutData: state => state.paymentMethod.data,
      payoutError: state => state.paymentMethod.error,
    }),
    hasCountry() {
      return !isNil(this.payoutPaymentMethod.country);
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.modalConfig = {
      title: this.$t('CRM.Payouts.account.title'),
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };

    this.csrevFeatureFlags = cookieManager.get('csrevFeatureFlags', true) ? JSON.parse(cookieManager.get('csrevFeatureFlags', true)) : null;

    this.isV6Version = this.csrevFeatureFlags ? this.csrevFeatureFlags['CSREV-3688-bo-payout'] : false;
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.crm.payouts, [
      'postUserPayoutPaymentMethod',
      'postCompanyPayoutPaymentMethod',
      'postPayoutSepaAddPaymentMethod',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),

    async savePayoutAccountForm() {
      const payoutPaymentMethod = { ...this.payoutPaymentMethod };
      payoutPaymentMethod.number = this.trimBlankSpaces(this.payoutPaymentMethod.number);

      const payload = { uuid: this.userUuid, payoutPaymentMethod };

      if (this.isV6Version) {
        const v6Payload = {
          country: this.payoutPaymentMethod.country,
          iban: payoutPaymentMethod.number,
          origin: document.location.href,
          owner: this.payoutPaymentMethod.holder,
          userUuid: this.userUuid,
        };

        await this.postPayoutSepaAddPaymentMethod(v6Payload);
      } else if (this.isCompany) {
        await this.postCompanyPayoutPaymentMethod(payload);
      } else {
        await this.postUserPayoutPaymentMethod(payload);
      }

      if (this.payoutStatus.ERROR) {
        const message = get(this.payoutError, 'message');
        this.$throwError({ message });
      } else {
        this.notify({ message: this.$t('CRM.Payouts.account.successMessage') });
        this.$emit('onSuccess');
        this.closeModal();
      }
    },

    closeModal() {
      this.payoutPaymentMethod = {
        holder: '',
        country: null,
        number: '',
      };
      this.$emit('input', false);
    },

    isFormAllValid(value) {
      this.isFormValid = value.detail.isValid && value.detail.areAllValidated;
    },

    onChangeHolder(value) {
      this.payoutPaymentMethod.holder = value.detail.trim();
    },

    onChangeNumber(value) {
      this.payoutPaymentMethod.number = friendlyFormatIBAN(value.detail.trim());
    },

    trimBlankSpaces(value) {
      return value.replace(/\s+/g, '');
    },

    checkIban(iban) {
      const trimmedIBAN = this.trimBlankSpaces(iban);
      const isValid = isValidIBAN(trimmedIBAN);
      const message = 'Please add a valid IBAN';

      return { isValid, message };
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="modalConfig"
    :is-open="value"
    @modal-closed="closeModal"
  >
    <div slot="body">
      <ui-validate
        class="d-flex flex-wrap mx-3"
        @status="isFormAllValid"
      >
        <ui-text-input
          v-validate.input="{
            isRequired: true,
          }"
          :value="payoutPaymentMethod.holder"
          :label="$t('CRM.Payouts.account.holderNameLabel')"
          name="holder"
          class="w-100 mb-2 order-1"
          @changevalue="onChangeHolder"
        />

        <MuiAlgoliaSelect
          v-model="payoutPaymentMethod.country"
          v-validate.select="{
            isRequired: true,
          }"
          :title="country => country.name"
          :label="$t('CRM.Payouts.account.countryLabel')"
          :placeholder="$t('CRM.Payouts.account.countryPlaceholder')"
          :index="ALGOLIA_INDEXES.countries"
          class="w-100 d-block order-2"
          path-value="code"
          name="country"
        />

        <ui-text-input
          v-show="hasCountry"
          v-validate.input="{
            isRequired: true,
            checkIban,
          }"
          :value="payoutPaymentMethod.number"
          :label="$t('CRM.Payouts.account.ibanLabel')"
          class="w-100 my-2 order-3"
          name="number"
          @changevalue="onChangeNumber"
        />
      </ui-validate>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end p-3">
        <CancelButton @click="closeModal" />
        <SaveButton
          :loading="payoutStatus.LOADING"
          :disabled="!isFormValid"
          class="mr-2"
          @click="savePayoutAccountForm"
        />
      </div>
    </template>
  </MuiModal>
</template>
